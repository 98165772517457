var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "in-layout",
    [
      _c(
        "template",
        { slot: "list" },
        [
          _c(
            "v-container",
            { attrs: { "grid-list-md": "" } },
            [
              _vm.showPendencyDialog
                ? _c("pendency-dialog", {
                    attrs: {
                      show: _vm.showPendencyDialog,
                      selectedLicense: _vm.selectedLicense,
                    },
                    on: { close: _vm.closePendencyDialog },
                  })
                : _vm._e(),
              _c(
                "v-layout",
                { staticClass: "ma-2", attrs: { column: "" } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "mb-4",
                      staticStyle: { cursor: "pointer" },
                      attrs: { tag: "span", to: { name: "Configurações" } },
                    },
                    [
                      _c("v-icon", { attrs: { size: "18" } }, [
                        _vm._v("mdi-chevron-left"),
                      ]),
                      _vm._v("\n          Voltar para configurações\n        "),
                    ],
                    1
                  ),
                  _c("h1", [_vm._v("Licenças cadastradas")]),
                  _c(
                    "v-layout",
                    { attrs: { row: "", "align-end": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              name: "search",
                              label: "Pesquisar Licença",
                              "prepend-inner-icon": "mdi-magnify",
                              clearable: "",
                            },
                            on: {
                              "click:clear": function ($event) {
                                return _vm.typing()
                              },
                              input: function ($event) {
                                return _vm.typing()
                              },
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-spacer"),
                      _vm.licenses.length
                        ? _c(
                            "v-flex",
                            { staticClass: "mb-2", attrs: { xs3: "" } },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    "justify-end": "",
                                    "align-center": "",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              Exibindo " +
                                      _vm._s(_vm.showingStart) +
                                      " - " +
                                      _vm._s(_vm.showingEnd) +
                                      " de " +
                                      _vm._s(_vm.totalItems) +
                                      "\n              "
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        disabled: _vm.pageNumber <= 1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.pageNumber -= 1
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-chevron-left"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        disabled:
                                          _vm.pageNumber >= _vm.totalPages,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.pageNumber += 1
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-chevron-right"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.licenses,
                  loading: _vm.loading,
                  "hide-actions": "",
                  "no-data-text": "Nenhuma Empresa com Licença Aprovada",
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c("td", [_vm._v(_vm._s(props.item.id))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.formatCpfCnpj(props.item.person.cpf_cnpj)
                            )
                          ),
                        ]),
                        _c("td", [_vm._v(_vm._s(props.item.person.name))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm.translateLicenseType(props.item.license_type)
                            )
                          ),
                        ]),
                        _c(
                          "td",
                          { staticClass: "text-xs-right" },
                          [
                            _c(
                              "v-btn",
                              { attrs: { small: "", icon: "", flat: "" } },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.edit(props.item)
                                      },
                                    },
                                  },
                                  [_vm._v("mdi-eye")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }