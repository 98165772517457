<template>
  <in-layout>
    <template slot="list">
      <v-container grid-list-md>
        <pendency-dialog
          v-if="showPendencyDialog"
          :show="showPendencyDialog"
          :selectedLicense = selectedLicense
          @close="closePendencyDialog"
        />

        <v-layout column class="ma-2">
          <router-link class="mb-4" style="cursor:pointer" tag="span" :to="{name: 'Configurações'}">
            <v-icon size="18">mdi-chevron-left</v-icon>
            Voltar para configurações
          </router-link>

          <h1>Licenças cadastradas</h1>

          <v-layout row align-end>
            <v-flex xs4>
              <v-text-field
                v-model="search"
                name="search"
                label="Pesquisar Licença"
                prepend-inner-icon="mdi-magnify"
                clearable
                @click:clear="typing()"
                @input="typing()"
              />
            </v-flex>

            <v-spacer />

            <v-flex xs3 class="mb-2" v-if="licenses.length">
              <v-layout justify-end align-center>
                Exibindo {{ showingStart }} - {{ showingEnd }} de {{ totalItems }}
                <v-btn
                  icon
                  @click="pageNumber-=1"
                  :disabled="pageNumber <= 1"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="pageNumber+=1"
                  :disabled="pageNumber >= totalPages"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-layout>

        <v-data-table
          :headers="headers"
          :items="licenses"
          :loading="loading"
          hide-actions
          no-data-text="Nenhuma Empresa com Licença Aprovada"
          class="elevation-1">
          <template slot="items" slot-scope="props">
            <td>{{ props.item.id }}</td>
            <td>{{ formatCpfCnpj(props.item.person.cpf_cnpj) }}</td>
            <td>{{ props.item.person.name }}</td>
            <td>{{ translateLicenseType(props.item.license_type) }}</td>
            <td class="text-xs-right">
              <v-btn small icon flat>
                <v-icon @click="edit(props.item)">mdi-eye</v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>
      </v-container>
    </template>
  </in-layout>
</template>

<script>
import InLayout from '@/layouts/InLayout.vue';
import LicenseService from '@/services/license-service';
import PendencyDialog from '@/components/settings/PendencyDialog.vue';
import ConfirmationDialog from '@/components/settings/ConfirmationDialog.vue';
import formatCpfCnpj from '@/utils/formatCpfCnpj';

export default {
  name: 'RegisteredLicenses',
  components: {
    InLayout,
    ConfirmationDialog,
    PendencyDialog,
  },
  mounted() {
    this.getLicenses();
  },
  data() {
    return {
      loading: false,
      licenses: [],
      search: '',
      totalItems: 0,
      totalPages: 0,
      pageNumber: 1,
      itemsPerPage: 20,
      formatCpfCnpj,
      selectedLicense: {},
      showPendencyDialog: false,
      headers: [
        {
          text: 'ID', value: 'id', sortable: false, width: 150,
        },
        {
          text: 'CPF/CNPJ', value: 'CPF_CNPJ', align: 'left', sortable: false, width: 250,
        },
        {
          text: 'Nome', value: 'name', align: 'left', sortable: false, width: 400,
        },
        {
          text: 'Licença', value: 'license', align: 'left', sortable: false, width: 400,
        },
        {
          text: 'Ações', value: 'actions', align: 'right', sortable: false, width: 100,
        },
      ],
    };
  },
  methods: {
    edit(license) {
      this.selectedLicense = license;
      this.showPendencyDialog = true;
    },
    closePendencyDialog() {
      this.showPendencyDialog = false;
      this.getLicenses();
    },
    getLicenses(pageNumber) {
      this.pageNumber = pageNumber || this.pageNumber;
      this.loading = true;

      LicenseService
        .getLicenses({
          page: this.pageNumber,
          limit: this.itemsPerPage,
          q: this.search || undefined,
          status: 'approved',
        })
        .then(({ data }) => {
          this.licenses = data.licenses;
          this.totalItems = data.total;
          this.totalPages = data.total_pages;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    translateLicenseType(type) {
      return {
        receiver: 'Destinador',
        transporter: 'Transportador',
        temporary_storage: 'Armazenador Temporário',
      }[type] || '--';
    },
    typing() {
      clearInterval(this.interval);
      this.interval = setTimeout(() => {
        this.getLicenses(1);
      }, 500);
    },
  },
  computed: {
    showingStart() {
      return (this.pageNumber - 1 === 0) ? 1 : (this.pageNumber - 1) * this.itemsPerPage;
    },
    showingEnd() {
      if (this.totalPages === this.pageNumber) {
        return this.totalItems;
      }
      return (this.pageNumber * this.itemsPerPage);
    },
  },
  watch: {
    pageNumber() {
      this.getLicenses(this.pageNumber);
    },
  },
};
</script>
